<template>
  <b-modal :active.sync="value" :can-cancel="false" id="selectZoneEdit">
    <div class="modal-body-edit-zone">
      <p class="zone-title text-center mb-10px">{{ $t('newEditReservation.changeZoneQuestion') }}</p>
      <p class="current-zone-text zone-title">
        <i class="icon_check_dotted icon-mks"></i>
        {{ $t('newEditReservation.currentZoneSelected') }}: <strong class="current-zone-name"> {{currentZoneName}}</strong>
      </p>
      <p class="zone-medium-text mt-20px mb-10px text-center">{{ $t('newEditReservation.availableZones') }}</p>

      <!-- Zonas -->
      <div class="zones-container">
        <!-- Zonas activas -->
        <div
          :class="{'not_selected' : zone !== false && zone.id !== item.id, 'image' : item.imageZone}"
          :key="'Zone#selected-' + key"
          @click="changeZone(item)"
          class="card_zone"
          v-for="(item, key) in zoneOption"
        >
          <div class="content_check">
            <b-checkbox
              :false-value="false"
              :true-value="item.id"
              class="check_zone"
              disabled
              size="is-small"
              style="width: auto !important; margin-top: 5px"
              v-model="zone.id"
            ></b-checkbox>
          </div>
          <div class="cont_info" v-if="!item.imageZone">
            <div class="title_zone fw600">{{ item.displayName }}</div>
          </div>
          <div class="cont_info" v-else>
            <div class="cont_image">
              <img :src="item.imageZone" :alt="item.displayName">
            </div>
            <div class="title_zone image fw600">{{ item.displayName }}</div>
          </div>
        </div>

        <!-- Zonas inactivas -->
        <div
          :class="{'image' : item.imageZone}"
          :key="'item#Zone-' + key"
          class="card_zone inactive"
          v-for="(item, key) in zoneOptionInactive"
        >
          <div class="cont_info not" v-if="!item.imageZone">
            <div class="title_zone inactive fw600">{{ item.displayName }}</div>
            <div class="no_availability fw600">{{ $t('unavailable') }}</div>
          </div>
          <div class="cont_info" v-else>
            <div class="cont_image unavailable"
              :style="'background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(' + item.imageZone +') no-repeat center center/cover;'">
              <div class="no_availability image fw600">{{ $t('unavailable') }}</div>
            </div>
            <div class="title_zone image fw600">{{ item.displayName }}</div>
          </div>
        </div>
      </div>

      <div class="content_description">
        <p class="zone-title text-center">{{ $t('newEditReservation.yourModifiedZone') }}</p>
        <p class="selected-zone-text zone-small-text">
          <i class="icon_check_dotted icon-mks"></i>
          <i>{{ zone.displayName ? $t('newEditReservation.yourNewZone') : $t('newEditReservation.selectNewZone') }} <strong>{{ zone.displayName }}</strong></i>
        </p>
        <div class="description_selected" v-html="zone.copy" v-if="vendor.id != 51"></div>
        <div class="description_selected" v-html="msg" v-if="msg != null"></div>
      </div>

      <div class="content_button">
        <button
          @click="hideModal"
          class="btn_zone secondary"
          type="submit"
        >{{ $t('cancel') }}</button>
        <button
          :class="{'disabled' : !zone}"
          :disabled="!zone"
          @click="confirm"
          class="btn_zone"
          type="submit"
        >{{ $t('confirm') }}</button>
      </div>
      <b-loading :active.sync="loading" :is-full-page="false" />
    </div>
  </b-modal>
</template>
<script>
// ================ Libraries =============
import { mapActions } from 'vuex';
// ================ Types  ================
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import _ from 'lodash';
import sectionTypes from '@/store/types/section';

export default {
  props: ['prevDate', 'value', 'editZone'],
  data () {
    return {
      zoneOption: [],
      zoneOptionInactive: [],
      zone: false,
      copy: null,
      msg: null,
      dayOfWeekZone: null,
      loading: true,
      datePrev: false,
      r: 0,
      p: 0
    };
  },
  computed: {
    currentZoneName () {
      if (this.zones.length > 0) {
        return this.zones.find(z => z.id === parseInt(this.editZone.zoneId)).displayName;
      }
      return '';
    }
  },
  watch: {
    zones () {
      this.zones.forEach(async item => {
        if (!item.selectSubZone) {
          if (this.selectedTypeReservation === 'Electrónica' && this.params.vendorId === 149) {
            if (item.isActive) {
              if (item.displayName !== 'VIP') {
                let d = await this.validateDispo(item.id);
                if (d > 0) {
                  this.zoneOption.push({
                    id: item.id,
                    displayName: item.displayName,
                    copy: item.copy,
                    imageZone: item.imageZone
                  });
                } else {
                  this.zoneOptionInactive.push({
                    id: item.id,
                    displayName: item.displayName,
                    copy: item.copy,
                    imageZone: item.imageZone
                  });
                }
              }
            }
          } else {
            if (item.isActive) {
              let d = await this.validateDispo(item.id);
              if (d > 0) {
                this.zoneOption.push({
                  id: item.id,
                  displayName: item.displayName,
                  copy: item.copy,
                  imageZone: item.imageZone
                });
              } else {
                this.zoneOptionInactive.push({
                  id: item.id,
                  displayName: item.displayName,
                  copy: item.copy,
                  imageZone: item.imageZone
                });
              }
            }
          }
        } else {
          if (item.isActive) {
            await item.subSections.forEach(async i => {
              let param = item.id + '=>' + i.id;
              let d = await this.validateDispo(param);
              if (d > 0) {
                this.zoneOption.push({
                  id: item.id + '=>' + i.id,
                  displayName: i.title,
                  copy: i.copy,
                  imageZone: i.imageSubZone
                });
              } else {
                this.zoneOptionInactive.push({
                  id: item.id + '=>' + i.id,
                  displayName: i.title,
                  copy: i.copy,
                  imageZone: i.imageSubZone
                });
              }
            });
          }
        }
      });
      this.loading = false;
    },
    zone () {
      this.dayOfWeekZone = parseInt(
        this.$moment(this.prevDate)
          .seconds(0)
          .minutes(0)
          .hours(0)
          .milliseconds(0)
          .format('d')
      );
      let zoneVitto = ['112=>57', '112=>58'];
      let id = this.zone.id;
      let exists = _.find(zoneVitto, (data) => {
        if (data === id) {
          return data;
        }
      });
      if (exists === undefined) {
        exists = false;
      } else {
        exists = true;
      }
      // eslint-disable-next-line
      if (this.vendor.id === 51 && (this.dayOfWeekZone === 5 || this.dayOfWeekZone === 4) && id == '112=>56') {
        this.msg = 'Esta es zona pet friendly.<br> En caso de lluvia tu reserva quedaría en lista de espera y cambiaría para otra zona.<br>Recuerda que nuestro restaurante tiene dos turnos de reservas por lo que tu mesa podría tener otra reserva dos horas después de la hora de tu reserva';
      } else if (this.vendor.id === 51 && (this.dayOfWeekZone === 5 || this.dayOfWeekZone === 4) && exists) {
        this.msg = 'Recuerda que nuestro restaurante tiene dos turnos de reservas por lo que tu mesa podría tener otra reserva dos horas después de la hora de tu reserva';
        // eslint-disable-next-line
      } else if (this.vendor.id === 51 && (this.dayOfWeekZone !== 5 || this.dayOfWeekZone !== 4) && id == '112=>56') {
        this.msg = 'Esta es zona pet friendly.<br> En caso de lluvia tu reserva quedaría en lista de espera y cambiaría para otra zona.';
      } else if (this.vendor.id === 54 && (this.dayOfWeekZone === 5 || this.dayOfWeekZone === 6 || this.dayOfWeekZone === 4)) {
        this.msg = 'Recuerda que nuestro restaurante tiene dos turnos de reservas por lo que tu mesa podría tener otra reserva dos horas después de la hora de  reservada.';
      } else {
        this.msg = null;
      }
    },
    zoneOption () {
      this.loading = false;
    },
    zoneOptionInactive () {
      this.loading = false;
    },
    prevDate () {
      this.datePrev = this.prevDate;
    }
  },
  mounted () {
    this.datePrev = this.prevDate;
    this.getZones({ vendorId: this.vendor.id });
  },
  methods: {
    ...mapActions({
      getZones: sectionTypes.actions.getZones
    }),
    changeZone (item) {
      if (item === this.zone) {
        this.zone = false;
      } else {
        this.zone = item;
      }
      this.selectZone();
      const tempArray = { ...this.zoneOption };
      this.zoneOption = tempArray;
    },
    hideModal () {
      this.zone = false;
      this.copy = null;
      this.msg = null;
      this.$store.commit(reservationTypes.mutations.setSelectedDate, false);
      this.$emit('input', false);
    },
    selectZone () {
      this.copy = this.zone.copy;
      this.$store.commit(
        reservationTypes.mutations.setSelectedZone,
        this.zone.id
      );
      if (this.zone) {
        window.localStorage.setItem('zoneId', parseInt(this.zone.id));
      } else {
        window.localStorage.removeItem('zoneId');
      }
      this.$store.commit(reservationTypes.mutations.setSelectedDate, false);
      if (this.editZone) {
        this.$emit('changeZone', this.zone.id);
      }
    },
    confirm () {
      this.$emit('input', false);
      this.$emit('hide-select-zone', true);
      // this.$emit('change-date', true)
      this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
      let dateInit = this.dateNowInitial();
      let response = this.setSelectedDates(
        this.datePrev,
        dateInit,
        this.allEvents,
        this.zone
      );
      this.$parent.$emit('isEventDay', response.evento);
      this.zone = false;
      this.copy = null;
      this.msg = null;
    },
    validateDispo: async function (item) {
      this.p++;
      var obs = this;
      let time = parseInt(new Date(this.datePrev).getTime());
      if (!this.datePrev) {
        time = parseInt(this.$moment().seconds(0).minutes(0).hours(0).milliseconds(0).format('x'));
      }
      await this.$store.dispatch({
        type: 'whiteLabel:avalibilityHourNoCommit',
        data: {
          timestamp: time,
          vendorId: this.$store.getters['whiteLabel:vendorId'],
          people: this.selectedPeople,
          zone: item,
          typeReservation: this.selectedTypeReservation,
          tableId: this.selectedTable
        }
      })
        .then(response => {
          obs.r = 0;
          response.forEach(i => {
            if (i.status === true) {
              obs.r++;
            }
          });
          this.p--;
        });
      return obs.r;
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/Select/selectZoneEdit.scss";
</style>
