<template>
  <div class="card-hour" id="hora_disponible" style>
    <h1 :style="{color: design.bgColorCheckout}" v-if="showTitle">{{ $t('selectView.schedule.text1') }}</h1>
    <p
      class="general-subtitle"
      v-if="Array.isArray(getAvalibilityHour) && getAvalibilityHour.length > 0 || getAvalibilityHour == null"
    >{{ $t('selectAvailableHour') }}</p>
    <div :style="{backgroundColor: design.bgColorCheckout}" class="separator10"></div>
    <p
      class="general-subtitle"
      v-if="Array.isArray(getAvalibilityHour) && getAvalibilityHour.length == 0 "
    >{{ $t('noAvailableTime') }}</p>
    <Hour
      :backgroundC="'#000000'"
      :color1="'#000000'"
      :editPeople="editPeople"
      :color2="'#000000'"
      :params="params"
      :sectionEdit="mysectionEdit"
      :time="getAvalibilityHour"
      :changeDay="false"
      :showCurrent="true"
      action="edit"
      class="m-top-20"
      v-if="getAvalibilityHour && !loadHour"
    ></Hour>
    <i class="icon-mks icon-precompro-loader-new snipper" v-if="loadHour"></i>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Hour from '@/components/Select/hour';
import whiteLabelTypes from '@/store/types/whiteLabel';
export default {
  props: ['showTitle', 'sectionEdit', 'editPeople'],
  data () {
    return {
      mysectionEdit: null
    };
  },
  components: { Hour },
  computed: {
    ...mapGetters({
      getAvalibilityHour: [whiteLabelTypes.getters.avalibilityHour],
      loadHour: whiteLabelTypes.getters.loadHour
    })
  },
  watch: {
    sectionEdit () {
      this.mysectionEdit = this.sectionEdit;
    }
  }
};
</script>
<style lang="scss">
#hora_disponible {
  max-width: 380px;
  position: relative;
  .snipper {
    position: absolute;
    top: 28%;
    left: 19%;
    opacity: 0.5;
    width: 60px;
    margin: 40px;
    -webkit-animation: spin 1.5s linear infinite;
    -moz-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
.general-subtitle {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  color: var(--bgColorCheckout) !important;
}
</style>
